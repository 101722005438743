import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { navigate } from 'gatsby';

import * as API from '../apis';
import { ACTION, CATEGORY } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MutedText from '../components/MutedText';
import Spinner from '../components/Spinner';
import NotFoundPic from '../images/not-found.jpg';

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-bottom: 1.875rem;
`;

const Title = styled.h1`
  margin: 0 0 1.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #10abe2;
`;

const LoadingIcon = styled(Spinner).attrs({ color: '#10abe2' })`
  width: 1.125em;
  height: 1.125em;
`;

function ActivatePage({ location }) {
  const { code } = queryString.parse(location.search);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigateToLogin = () => {
    setTimeout(() => navigate('/login/'), 5000);
  };

  useEffect(() => {
    (async () => {
      if (!code) {
        setErrorMessage('Mã kích hoạt không hợp lệ.');
        return;
      }

      try {
        setLoading(true);
        await API.activateAccount(code);
        setLoading(false);
        setSuccessMessage(
          'Tài khoản đã được kích hoạt thành công. Bạn sẽ được tự động chuyển tới trang đăng nhập sau 5s.'
        );
        navigateToLogin();
        ReactGA.event({
          category: CATEGORY.USER_MANAGEMENT,
          action: ACTION.ACTIVATE_ACCOUNT_SUCCESSFUL,
        });
      } catch (error) {
        setLoading(false);
        console.error(error);
        setErrorMessage(error.response.data.message);
        ReactGA.event({
          category: CATEGORY.USER_MANAGEMENT,
          action: ACTION.ACTIVATE_ACCOUNT_FAILED,
        });
      }
    })();
  }, []);

  return (
    <Layout>
      <SEO title="Kích hoạt tài khoản" />

      <Title>Kích hoạt tài khoản</Title>
      {!loading && errorMessage && (
        <>
          <MutedText>{errorMessage}</MutedText>
          <br />
          <img src={NotFoundPic} alt="Không tìm thấy kết quả" />
        </>
      )}
      <VideoList>
        {loading && (
          <>
            <LoadingIcon />
          </>
        )}
        {!loading && successMessage}
      </VideoList>
    </Layout>
  );
}

ActivatePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default ActivatePage;
